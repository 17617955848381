import { useIntl } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';

import {
  ProductAvailabilityStatusEnum,
  ProductListLatestAvailabilityResponse,
} from '@matchastock/api-contract/public/product';

import { cn } from '~/utils/css';
import { formatBrandName, formatVendorName } from '~/utils/formatters';

import { AvailabilityToggle } from './availability-toggle';
import { DataTable } from './data-table';
import { LocalDateTime } from './local-date-time';

type Product = ProductListLatestAvailabilityResponse[number];

const columnHelper = createColumnHelper<Product>();

const ProductName = ({
  value,
  className,
}: {
  value: Product;
  className?: string;
}) => {
  return (
    <a
      href={value.product.url}
      target="_blank"
      className={cn(
        'block underline decoration-1 underline-offset-2 hover:text-accent-foreground-hover focus:text-accent-foreground-hover',
        className,
      )}
      rel="noreferrer"
    >
      {value.product.name}
    </a>
  );
};

const ProductVendor = ({
  value,
  className,
}: {
  value: Product;
  className?: string;
}) => {
  const content = formatVendorName(value.product.vendor);
  if (!content) {
    return null;
  }

  return <span className={cn(className)}>{content}</span>;
};

const ProductBrand = ({
  value,
  className,
}: {
  value: Product;
  className?: string;
}) => {
  const content = formatBrandName(value.product.brand);
  if (!content) {
    return null;
  }

  return <span className={cn(className)}>{content}</span>;
};

const StatusBadge = ({
  status,
  className,
}: {
  status: ProductAvailabilityStatusEnum;
  className?: string;
}) => {
  return (
    <span
      className={cn(
        'border text-white inline-flex items-center content-center px-[0.25rem] py-[0.125rem] uppercase tracking-wider text-xs rounded-sm font-sans',
        status === 'available' && [
          'bg-success text-success-accent border-success-accent',
        ],
        status === 'unavailable' && [
          'bg-destructive text-destructive-accent border-destructive-accent',
        ],
        className,
      )}
    >
      {status === 'available' ? 'Available' : 'Unavailable'}
    </span>
  );
};

const columns = [
  columnHelper.accessor('product.name', {
    id: 'product_name',
    header: 'Matcha',
    cell(info) {
      return (
        <div>
          <ProductName value={info.row.original} />
          <ProductBrand className="text-xs" value={info.row.original} />
        </div>
      );
    },
  }),
  columnHelper.accessor('product.vendor', {
    id: 'product_vendor',
    header: 'Vendor',
    size: 70,
    cell(info) {
      return <ProductVendor value={info.row.original} />;
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    enableSorting: false,
    enableGlobalFilter: false,
    size: 80,
    cell(info) {
      return <StatusBadge status={info.getValue()} />;
    },
  }),
  columnHelper.accessor('checkedAt', {
    header: 'Updated',
    enableGlobalFilter: false,
    size: 80,
    cell(info) {
      return <LocalDateTime value={info.getValue()} />;
    },
  }),
];

type Props = {
  data: Product[];
  isAvailable: boolean;
};

export function LatestAvailabilityTable(props: Props) {
  const t = useIntl();

  return (
    <DataTable<Product>
      columns={columns}
      data={props.data}
      filters={<AvailabilityToggle isChecked={props.isAvailable} />}
      initialState={{
        sorting: [{ id: 'product_name', desc: false }],
      }}
      renderBelowSm={(rows) => {
        if (rows.length === 0) {
          return (
            <div className="p-4 border-2 border-white rounded-md flex products-center justify-center">
              No matcha found
            </div>
          );
        }
        return (
          <ul className="grid grid-flow-row gap-8">
            {rows.map((row) => {
              const value = row.original;
              return (
                <li
                  key={value.id}
                  className="py-4 px-4 border-2 border-white flex flex-col gap-y-4 rounded-md"
                >
                  <div className="flex flex-row justify-between items-baseline gap-x-4">
                    <ProductBrand value={value} className="text-sm" />

                    <StatusBadge
                      status={value.status}
                      className="text-xs w-fit"
                    />
                  </div>

                  <div className="flex flex-row justify-between items-baseline gap-x-4">
                    <ProductName
                      value={value}
                      className="text-lg leading-snug"
                    />
                  </div>

                  <div className="flex flex-row justify-between items-baseline gap-x-4">
                    <ProductVendor className="text-sm" value={value} />

                    <p className="text-xs">
                      <LocalDateTime value={value.checkedAt} />
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        );
      }}
      formatTotal={({ rows, total }) => {
        const availableCount = rows.filter((r) => {
          return r.original.status === 'available';
        }).length;

        return (
          <p>
            {t.formatMessage(
              {
                id: 'DataTable.availableCount',
                defaultMessage: '{availableCount, plural, other {# available}}',
              },
              {
                availableCount,
              },
            )}
            {' / '}
            {t.formatMessage(
              {
                id: 'DataTable.rowCount',
                defaultMessage: '{count, plural, other {# total}}',
              },
              { count: total },
            )}
          </p>
        );
      }}
    />
  );
}
