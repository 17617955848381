export function BackToTopButton() {
  return (
    <button
      className="bg-foreground text-background px-4 py-1 rounded-full text-sm"
      onClick={() => {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      }}
    >
      Back to top
    </button>
  );
}
