import {
  PRODUCT_BRAND_ENUM_VALUES,
  PRODUCT_VENDOR_ENUM_VALUES,
  ProductBrandEnum,
  ProductVendorEnum,
} from '@matchastock/api-contract/admin/product';

export function formatBrandName(brand: ProductBrandEnum): string | null {
  switch (brand) {
    case PRODUCT_BRAND_ENUM_VALUES.mk:
      return 'Marukyu Koyamaen';
    default:
      return null;
  }
}

export function formatVendorName(vendor: ProductVendorEnum): string | null {
  switch (vendor) {
    case PRODUCT_VENDOR_ENUM_VALUES.mk:
      return 'Marukyu Koyamaen';
    case PRODUCT_VENDOR_ENUM_VALUES.sazen:
      return 'Sazen';
    default:
      return null;
  }
}
