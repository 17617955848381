import { json, LoaderFunctionArgs } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { ExternalScriptsHandle } from 'remix-utils/external-scripts';
import { z } from 'zod';

import { BackToTopButton } from '~/components/back-to-top-button';
import { LatestAvailabilityTable } from '~/components/latest-availability-table';
import { createMatchaStockClient } from '~/utils/matchastock-client.server';
import { validateSearchParamsFromRequest } from '~/utils/search-params';

const searchParamsSchema = z.object({
  is_available: z
    .enum(['y', 'n'])
    .nullish()
    .catch(null)
    .transform((v) => {
      if (v == null) {
        return null;
      }
      if (v === 'y') {
        return true;
      }
      return false;
    }),
});

export const loader = async (args: LoaderFunctionArgs) => {
  const searchParams = validateSearchParamsFromRequest({
    request: args.request,
    schema: searchParamsSchema,
  });

  const msClient = createMatchaStockClient(args);

  const productsRes = await msClient.products.listLatestAvailability({
    query: {
      is_available: Boolean(searchParams.is_available),
    },
  });

  if (productsRes.status !== 200) {
    throw json({}, productsRes.status);
  }

  return json({
    products: productsRes.body,
    filters: {
      isAvailable: Boolean(searchParams.is_available),
    },
  });
};

export const handle: ExternalScriptsHandle = {
  scripts: [
    {
      src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3659350068068241',
      async: true,
      crossOrigin: 'anonymous',
    },
  ],
};

export default function AppPage() {
  const { products, filters } = useLoaderData<typeof loader>();

  return (
    <main className="flex flex-col items-center px-4 pb-4 sm:px-8 sm:pb-8 gap-y-16">
      <LatestAvailabilityTable
        data={products}
        isAvailable={filters.isAvailable}
      />
      {products.length > 5 && (
        <div className="block sm:hidden">
          <BackToTopButton />
        </div>
      )}
    </main>
  );
}
