import { ReactNode, useState } from 'react';

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Header,
  Row,
  RowData,
  SortDirection,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import { useDebounce } from '@uidotdev/usehooks';
import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon } from 'lucide-react';

import { cn } from '~/utils/css';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './table';

type Props<TData extends RowData> = Omit<
  TableOptions<TData>,
  'getCoreRowModel' | 'getSortedRowModel' | 'getFilteredRowModel'
> & {
  className?: string;
  tableContainerClassName?: string;
  filters?: ReactNode;
  renderBelowSm: (data: Row<TData>[]) => ReactNode;
  formatTotal: ({
    rows,
    total,
  }: {
    rows: Row<TData>[];
    total: number;
  }) => ReactNode;
};

export function DataTable<TData extends RowData>(props: Props<TData>) {
  const {
    className,
    tableContainerClassName,
    filters,
    renderBelowSm,
    formatTotal,
    ...tableProps
  } = props;

  const [globalFilter, setGlobalFilter] = useState<string>('');
  const debouncedGlobalFilter = useDebounce(globalFilter, 750);

  const table = useReactTable<TData>({
    enableGlobalFilter: true,
    ...tableProps,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      ...(tableProps.initialState ?? {}),
    },
    state: {
      ...(tableProps.state ?? {}),
      globalFilter: debouncedGlobalFilter,
    },
  });

  const rows = table.getRowModel().rows;

  return (
    <div className={cn('space-y-2', className)}>
      <div className="flex flex-col items-center sm:flex-row sm:items-baseline justify-between gap-x-8 gap-y-4">
        {formatTotal({ rows, total: table.getRowCount() })}

        <label className="sr-only" htmlFor="search">
          Search
        </label>

        <div className="flex flex-col sm:flex-row items-baseline gap-x-8 gap-y-4">
          {filters}
          <input
            id="search"
            name="search"
            className="max-w-[200px] text-sm bg-background text-foreground border-2 rounded-md px-2 py-1"
            placeholder="e.g. Isuzu, Sazen"
            type="search"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </div>
      </div>

      <div
        className={cn(
          'w-full h-full border-2 rounded-lg max-h-[600px] overflow-auto hidden sm:block',
          tableContainerClassName,
        )}
      >
        <Table className="relative table-fixed">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return <DataTableHead key={header.id} header={header} />;
                  })}
                </TableRow>
              );
            })}
          </TableHeader>

          <TableBody>
            {rows.length ? (
              rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}px`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={tableProps.columns.length}
                  className="text-center"
                >
                  No matcha found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <div className="block sm:hidden">{renderBelowSm(rows)}</div>
    </div>
  );
}

const DataTableHead = <TData extends RowData>({
  header,
}: {
  header: Header<TData, unknown>;
}) => {
  const content = header.isPlaceholder
    ? null
    : flexRender(header.column.columnDef.header, header.getContext());

  return (
    <TableHead
      key={header.id}
      className="sticky top-0 z-10 p-0 text-foreground"
      style={{
        width: `${header.getSize()}px`,
      }}
    >
      {header.column.getCanSort() ? (
        <button
          onClick={header.column.getToggleSortingHandler()}
          className="flex flex-row items-center gap-x-1 w-full h-full px-4 py-2 group"
        >
          {content}
          <SortDirectionIcon direction={header.column.getIsSorted()} />
        </button>
      ) : (
        <div className="px-4 py-2">{content}</div>
      )}
    </TableHead>
  );
};

const SortDirectionIcon = ({
  direction,
}: {
  direction: SortDirection | false;
}) => {
  switch (direction) {
    case false:
      return <ArrowUpDownIcon size="0.875rem" />;
    case 'asc':
      return <ArrowUpIcon size="0.875rem" />;
    case 'desc':
      return <ArrowDownIcon size="0.875rem" />;
    default:
      return null;
  }
};
