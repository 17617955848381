import {
  productBrandEnum,
  productIdSchema,
  productVendorEnum
} from "./chunk-7JHUILQA.mjs";

// src/admin/product.ts
import {
  initContract
} from "@ts-rest/core";
import { z } from "zod";
var c = initContract();
var adminProductSchema = z.object({
  id: productIdSchema,
  brand: productBrandEnum,
  externalId: z.string(),
  isPrincipal: z.boolean(),
  isVisible: z.boolean(),
  name: z.string(),
  url: z.string().url(),
  vendor: productVendorEnum
});
var productContract = c.router(
  {
    list: {
      method: "GET",
      path: "/",
      query: z.object({}),
      responses: {
        200: adminProductSchema.array()
      }
    }
  },
  {
    pathPrefix: "/products"
  }
);

export {
  adminProductSchema,
  productContract
};
