import { useCallback } from 'react';

import { useSearchParams } from '@remix-run/react';

import { Switch } from './ui/switch';

type Props = {
  isChecked: boolean;
};

export function AvailabilityToggle(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = useCallback(
    (isChecked: boolean) => {
      const nextSearchParams = new URLSearchParams(searchParams);
      if (isChecked) {
        nextSearchParams.set('is_available', 'y');
      } else {
        nextSearchParams.delete('is_available');
      }

      setSearchParams(nextSearchParams);
    },
    [searchParams, setSearchParams],
  );

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="flex items-center gap-x-2 justify-center w-full">
      <span className="text-sm">Only available</span>
      <Switch
        checked={props.isChecked}
        onCheckedChange={(nextIsChecked) => {
          handleChange(nextIsChecked);
        }}
      />
    </label>
  );
}
