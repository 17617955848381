import {
  brandedId
} from "./chunk-MZLJHBZU.mjs";

// src/shared/product.ts
import { z } from "zod";
var productIdSchema = brandedId("Product");
var productAvailabilityIdSchema = brandedId("ProductAvailability");
var productBrandEnum = z.enum(["mk"]);
var PRODUCT_BRAND_ENUM_VALUES = productBrandEnum.Values;
var productVendorEnum = z.enum(["mk", "sazen"]);
var PRODUCT_VENDOR_ENUM_VALUES = productVendorEnum.Values;
var productAvailabilityStatusEnumSchema = z.enum([
  "available",
  "unavailable"
]);
var PRODUCT_AVAILABILITY_STATUS_ENUM_VALUES = productAvailabilityStatusEnumSchema.Values;

export {
  productIdSchema,
  productAvailabilityIdSchema,
  productBrandEnum,
  PRODUCT_BRAND_ENUM_VALUES,
  productVendorEnum,
  PRODUCT_VENDOR_ENUM_VALUES,
  productAvailabilityStatusEnumSchema,
  PRODUCT_AVAILABILITY_STATUS_ENUM_VALUES
};
